import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image"

const HeroTierTwo = ({ heroHeading, tierTwoImage, tierTwoMobileImage }) => {
  if ((tierTwoImage === undefined || tierTwoImage.length === 0) && !heroHeading)
    return null

  let image = null

  if (tierTwoMobileImage.length) {
    image = withArtDirection(getImage(tierTwoImage[0].localFile), [
      {
        media: "(max-width: 1024px)",
        image: getImage(tierTwoMobileImage[0].localFile),
      },
    ])
  } else if (tierTwoImage.length) {
    image = getImage(tierTwoImage[0].localFile)
  }

  return (
    <div className="px-ogs lg:grid lg:grid-cols-12 lg:gap-gs pt-8 lg:pt-30">
      <div className="max-w-md lg:max-w-none lg:col-start-2 lg:col-span-10 lg:text-center mr-ogs lg:mr-0">
        {heroHeading && (
          <h1 className="font-semibold text-5xl 2xl:text-6xl mb-15 lg:mb-8 whitespace-pre-line">
            {heroHeading}
          </h1>
        )}
      </div>
      <div className="lg:col-span-12 rounded-t-4xl overflow-hidden">
        {image && (
          <GatsbyImage
            className="pb-1:1 h-0 lg:h-80 3xl:h-96 lg:pb-0 w-full"
            image={image}
            alt={tierTwoImage[0].alt || heroHeading}
          />
        )}
      </div>
    </div>
  )
}

HeroTierTwo.propTypes = {
  heroHeading: PropTypes.string,
  heroImage: PropTypes.shape(),
}

export default HeroTierTwo
