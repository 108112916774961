import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image"

import Signature from "../Signature/Signature"

const HeroTierOne = ({
  heroHeading,
  tierOneImage,
  tierOneMobileImage,
  signatureName,
  signatureText,
}) => {
  if (
    (tierOneImage === undefined || tierOneImage?.length === 0) &&
    !heroHeading
  )
    return null

  let image = null
  let imageObj = null

  if (typeof tierOneImage === "string") {
    image = tierOneImage
  } else {
    if (
      tierOneImage.length &&
      tierOneMobileImage.length &&
      tierOneImage[0].localfile
    ) {
      imageObj = withArtDirection(getImage(tierOneImage[0].localFile), [
        {
          media: "(max-width: 768px)",
          image: getImage(tierOneMobileImage[0].localFile),
        },
      ])
    } else if (tierOneImage.length) {
      imageObj = getImage(tierOneImage[0].localFile)
    } else if (tierOneMobileImage.length) {
      imageObj = getImage(tierOneMobileImage[0].localFile)
    }
  }

  return (
    <div className="relative space-y-15 overflow-hidden px-ogs pt-10 lg:grid lg:grid-cols-12 lg:items-center lg:gap-gs lg:space-y-0">
      <div className="relative z-10 max-w-md lg:col-span-4 lg:col-start-9 lg:row-start-1 lg:max-w-none">
        {heroHeading && (
          <h1 className="whitespace-pre-line align-middle text-5xl font-semibold lg:my-12 2xl:text-6xl">
            {heroHeading}
          </h1>
        )}
      </div>
      <div className="relative z-10 -ml-ogs lg:col-span-7 lg:row-start-1 lg:-mr-gs lg:self-end">
        {imageObj && (
          <GatsbyImage
            className="w-full transform-gpu overflow-hidden rounded-tr-4xl"
            image={imageObj}
            alt={tierOneImage[0].alt || heroHeading}
          />
        )}
        {image && (
          <div className="pb-4:3 relative h-0 w-full overflow-hidden rounded-tr-4xl">
            <img
              className="absolute inset-0 h-full w-full object-cover"
              src={image}
              alt={tierOneImage[0].alt || heroHeading}
            />
          </div>
        )}
      </div>

      {signatureName && signatureText && (
        <div className="absolute left-ogs bottom-5 z-10 w-48 md:bottom-15">
          <Signature name={signatureName} text={signatureText} />
        </div>
      )}
    </div>
  )
}

HeroTierOne.propTypes = {
  heroHeading: PropTypes.string,
  heroImage: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  signatureName: PropTypes.string,
  signatureText: PropTypes.string,
}

export default HeroTierOne
