import React from "react"
import PropTypes from "prop-types"

import {
  BLUE,
  PINK,
  TIER_ONE,
  TIER_THREE,
  TIER_TWO,
  YELLOW,
} from "../../constants"

import HeroTierOne from "./HeroTierOne"
import HeroTierTwo from "./HeroTierTwo"
import HeroTierThree from "./HeroTierThree"

const Hero = ({
  heroHeading,
  tierOneImage,
  tierOneMobileImage,
  tierTwoImage,
  tierTwoMobileImage,
  heroStyle,
  theme,
  signatureName,
  signatureText,
}) => {
  const renderHero = heroStyle => {
    switch (heroStyle) {
      case TIER_ONE:
        return (
          <HeroTierOne
            heroHeading={heroHeading}
            tierOneImage={tierOneImage}
            tierOneMobileImage={tierOneMobileImage}
            theme={theme}
            signatureName={signatureName}
            signatureText={signatureText}
          />
        )
      case TIER_TWO:
        return (
          <HeroTierTwo
            heroHeading={heroHeading}
            tierTwoImage={tierTwoImage}
            tierTwoMobileImage={tierTwoMobileImage}
          />
        )
      case TIER_THREE:
        return <HeroTierThree heroHeading={heroHeading} />
      default:
        return (
          <HeroTierOne heroHeading={heroHeading} heroImage={tierOneImage} />
        )
    }
  }

  const getBackgroundClass = () => {
    switch (theme) {
      case BLUE:
        return "bg-blue-500"
      case PINK:
        return "bg-pink-500"
      case YELLOW:
        return "bg-yellow-500"
      default:
        return "bg-blue-500"
    }
  }

  return (
    <div className={`text-white ${getBackgroundClass()}`}>
      {renderHero(heroStyle)}
    </div>
  )
}

Hero.propTypes = {
  heroHeading: PropTypes.string,
  tierOneImage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  heroStyle: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  signatureName: PropTypes.string,
  signatureText: PropTypes.string,
}

export default Hero
