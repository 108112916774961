import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout/Layout"
import FlexibleContent from "../components/FlexibleContent"
import Hero from "../components/Hero/Hero"

const FlexibleContentPageTemplate = ({ data, location }) => {
  const {
    metaTitle,
    metaDescription,
    theme,
    title,
    flexibleContent,
    heroHeading,
    tierOneImage,
    tierOneMobileImage,
    tierTwoImage,
    tierTwoMobileImage,
    heroStyle,
  } = data.craftPagesFlexibleContentEntry

  return (
    <Layout
      title={metaTitle || title}
      description={metaDescription}
      theme={theme}
      location={location}
    >
      <div className="js-transition-link-target" data-theme={theme}>
        <Hero
          heroStyle={heroStyle}
          heroHeading={heroHeading}
          tierOneImage={tierOneImage}
          tierOneMobileImage={tierOneMobileImage}
          tierTwoImage={tierTwoImage}
          tierTwoMobileImage={tierTwoMobileImage}
          theme={theme}
        />
        <FlexibleContent flexibleContent={flexibleContent} />
      </div>
    </Layout>
  )
}

export default FlexibleContentPageTemplate

export const query = graphql`
  query FlexibleContentQuery($slug: String) {
    craftPagesFlexibleContentEntry(slug: { eq: $slug }) {
      title
      theme
      metaTitle
      metaDescription
      heroHeading
      tierOneImage: heroImage {
        ... on Craft_images_Asset {
          id
          title
          alt
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 80, aspectRatio: 1.77)
            }
          }
        }
      }
      tierOneMobileImage: heroImageSecondary {
        ... on Craft_images_Asset {
          id
          title
          alt
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 80, aspectRatio: 1)
            }
          }
        }
      }
      tierTwoImage: heroImage {
        ... on Craft_images_Asset {
          id
          title
          alt
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 80, aspectRatio: 3.4)
            }
          }
        }
      }
      tierTwoMobileImage: heroImageSecondary {
        ... on Craft_images_Asset {
          id
          title
          alt
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 80, aspectRatio: 1)
            }
          }
        }
      }
      heroStyle
      flexibleContent {
        ...flexibleContentFragment
      }
    }
  }
`
