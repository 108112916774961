import React from "react"
import PropTypes from "prop-types"

import LogoMark from "../SVG/Logomark"

const HeroTierThree = ({ heroHeading }) => {
  if (!heroHeading) return null
  return (
    <div className="relative px-ogs lg:grid lg:grid-cols-12 lg:gap-gs pt-24 pb-40 lg:pt-30 overflow-hidden">
      <div className="lg:col-start-4 lg:col-span-6 text-center relative z-10">
        <h1 className="font-semibold text-5xl 2xl:text-6xl whitespace-pre-line">
          {heroHeading}
        </h1>
      </div>
      <div className="opacity-20 absolute inset-y-0 left-0 transform -rotate-12 -ml-24 mt-18 -mb-12 sm:mt-28 sm:ml-auto sm:left-auto sm:-right-48 sm:-mb-24">
        <LogoMark />
      </div>
    </div>
  )
}

HeroTierThree.propTypes = {
  heroHeading: PropTypes.string,
}

export default HeroTierThree
